<!-- 路内车场-车场信息 -->
<template>
    <div class='content'>
        <parkingInformation :isCurPark="true"></parkingInformation>
    </div>
</template>

<script>
import parkingInformation from '../../../components/parkingInformation/index.vue'
export default {
  name: '',
  components: { parkingInformation },
  props: {},
  data () {
    return {
    }
  },
  watch: {},
  computed: {},
  methods: {},
  created () {},
  mounted () {}
}
</script>

<style scoped>

</style>
